<template>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>

    <!-- start: page -->
    <div class="row">
      <div class="col"></div>
    </div>
    <!-- end: page -->
  </section>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      title: "Gifts"
    };
  },
  methods: {},
  mounted: function() {}
};
</script>
